/* eslint-disable no-console */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BACKEND_URL, CSRF_TOKEN_KEY } from '@/constants';
import { FETCH_METHODS } from '@/types';

type FetchParams = {
  url: string;
  method?: FETCH_METHODS;
  body?: Record<string, any>;
  locale?: string;
};

export async function fetchData({
  url,
  method = FETCH_METHODS.GET,
  body,
  locale,
}: FetchParams) {
  const translate = locale ? `?lang=${locale}` : '';

  try {
    const fetchUrl = `${BACKEND_URL}/api/app${url}${translate}`;

    const options: AxiosRequestConfig = {
      method,
      // withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (body) {
      const lsCsrf = localStorage.getItem(CSRF_TOKEN_KEY);
      const csrfData = JSON.parse(lsCsrf ?? '{}');

      const data = {
        csrf_name: csrfData.csrf_name,
        csrf_value: csrfData.csrf_value,
        ...body,
      };
      options.data = data;
    }

    const response = await axios(fetchUrl, options);

    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching data');
    if (error instanceof AxiosError) {
      return error.response;
    }
  }
}
