import { PAGES_WITH_PYRUS_CHAT } from '@/constants';
import { WithLoader } from '@/providers/WithLoader';
import { WithRedux } from '@/providers/WithRedux';
import { csrfResresh } from '@/services/csrfResresh';
import '@/styles/globals.scss';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';

export default function App({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  const pageWithPyrus = PAGES_WITH_PYRUS_CHAT.includes(pathname);
  const [pyrusLoaded, setPyrusLoaded] = useState(false);

  useEffect(() => {
    const chat = document.getElementById('pyrus-widget-container-element');

    if (pageWithPyrus && chat && pyrusLoaded) {
      chat.style.display = 'block';
    }

    if (!pageWithPyrus && chat) {
      chat.style.display = 'none';
    }
  }, [pageWithPyrus, pyrusLoaded]);

  useEffect(() => {
    const getCSRF = async () => {
      await csrfResresh();
    };

    getCSRF();

    const mindboxInit = `
    mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
    mindbox.queue = mindbox.queue || [];
    mindbox('create');
    `;
    const mindboxInitFunc = new Function(mindboxInit);
    mindboxInitFunc();

    const yandexMetricsInit = `
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
     m[i].l=1*new Date();
     for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
     k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
     (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
  
     ym(51000866, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
     });
     `;
    const yandexMetricsInitFunc = new Function(yandexMetricsInit);
    yandexMetricsInitFunc();

    const vkPixelMetricsInit = `
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({id: "3250487", type: "pageView", start: (new Date()).getTime(), pid:   "USER_ID"});
    (function (d, w, id) {
    if (d.getElementById(id)) return;
    var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true;   ts.id = id;
    ts.src = "https://top-fwz1.mail.ru/js/code.js";
    var f = function () {var s = d.getElementsByTagName("script")[0];        s.parentNode.insertBefore(ts, s);};
    if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
    })(document, window, "tmr-code");
    `;
    const vkPixelMetricsInitFunc = new Function(vkPixelMetricsInit);
    vkPixelMetricsInitFunc();

    const adriverMetricsInit = `
    window.adrCounterStorage = window.adrCounterStorage || []; window.adrCounterStorage.push(() => {
    new AdriverCounterJS({sid:228801, bt:62},{id:"",gid1:"",yid1:""})
    });
    `;
    const adriverMetricsInitFunc = new Function(adriverMetricsInit);
    adriverMetricsInitFunc();
  }, []);

  return (
    <>
      <Head>
        <title>Остров Мечты</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <WithRedux>
        <WithLoader>
          <Component {...pageProps} />
          <Toaster
            toastOptions={{
              error: {
                duration: 5000,
              },
              success: {
                duration: 5000,
              },
            }}
          />
        </WithLoader>
      </WithRedux>
      <Script src="https://api.mindbox.ru/scripts/v1/tracker.js" async />
      <Script src="https://content.adriver.ru/AdRiverFPS.js" async />
      <Script
        src="https://pyrus.com/js/webwidget?id=NRXRtG~lDtc~rW-wScMNJIhZ65PFUc5kZS5jn5l2Neii36GCfNX2958nmAeB~RWyz0vmqOr4dl1wKWbDbm2qLbBHRHPOsr8F8F~W8cu8aKO0kBSTw4XNHc68I2M2YtWo3pT-WA%3D%3D"
        async
        onLoad={() => setPyrusLoaded(true)}
      />
      <noscript>
        <div>
          <img
            loading="lazy"
            src="https://top-fwz1.mail.ru/counter?id=3250487;js=na"
            style={{ position: 'absolute', left: '-9999px' }}
            alt="Top.Mail.Ru"
          />
        </div>
      </noscript>
      <noscript>
        <div>
          <img
            loading="lazy"
            src="https://mc.yandex.ru/watch/51000866"
            style={{ position: 'absolute', left: '-9999px' }}
            alt=""
          />
        </div>
      </noscript>
    </>
  );
}
