/* eslint-disable no-console */
import axios, { AxiosError } from 'axios';
import { ACCESS_TOKEN_KEY, BACKEND_URL, REFRESH_TOKEN_KEY } from '@/constants';

export const deleteToken = async () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  if (accessToken) {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/security/revoke`,
        {
          token: refreshToken,
        },
        {
          // withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(
          'An error occurred during log out:',
          error.response?.data.error_description,
        );
        return error.response?.data.message;
      }
    }
  }
  return null;
};
