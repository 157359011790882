import Link from 'next/link';
import styles from './Socials.module.scss';
import { SocialType } from '@/types';
import { useTranslations } from '@/hooks/useTranslations';

export type SocialProps = {
  img: string;
  alt: string;
};

const links: SocialProps[] = [
  {
    img: '/svg/telegram.svg',
    alt: 'tg',
  },
  {
    img: '/svg/tiktok.svg',
    alt: 'tiktok',
  },
  {
    img: '/svg/vk.svg',
    alt: 'vk',
  },
  {
    img: '/svg/youtube.svg',
    alt: 'youtube',
  },
];

function Social({ code, href }: { href: string; code: string }) {
  const getSocialData = (code: string) => {
    return links.filter((item) => item.alt === code)[0];
  };

  return (
    <Link className={styles.social} href={href} target="_blank">
      <img
        loading="lazy"
        src={getSocialData(code).img}
        alt={getSocialData(code).alt}
        width={24}
        height={24}
      />
    </Link>
  );
}

export function Socials({ socials }: { socials: SocialType[] }) {
  const translations = useTranslations();

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{translations.ourSocials}</p>
      <div className={styles.list}>
        {socials.map(({ link, code }, index) => (
          <Social key={code + index} href={link} code={code} />
        ))}
      </div>
    </div>
  );
}
