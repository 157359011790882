import cn from 'classnames';
import styles from './VacancyBlock.module.scss';
import { Tag } from '../Tag';
import { Button } from '../Button';
import { useRouter } from 'next/router';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { BACKEND_URL, MOBILE_RESOLUTION } from '@/constants';

type VacancyBlockProps = {
  text: string;
  tag: string;
  button: string;
  link: string;
  image: string;
  imageMob: string;
  className?: string;
};

export function VacancyBlock({
  text,
  tag,
  button,
  link,
  image,
  className,
  imageMob,
}: VacancyBlockProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const router = useRouter();
  const width = useWindowWidth();
  const isMobile = width && width < MOBILE_RESOLUTION;

  const handleButtonClick = () => {
    router.push(link);
  };

  const imageUrl =
    isMobile && imageMob
      ? `${BACKEND_URL}${imageMob}`
      : image
        ? `${BACKEND_URL}${image}`
        : '/png/empty.png';

  return (
    <div className={wrapperClassName}>
      {tag ? <Tag text={tag} /> : <div />}
      <div className={styles.info}>
        <h4 className={styles.title}>{text}</h4>
        <Button text={button} onClick={handleButtonClick} />
      </div>
      <img src={imageUrl} alt="vacancies" className={styles.img} />
      <div className={styles.shadow} />
    </div>
  );
}
