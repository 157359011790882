import { useState } from 'react';
import styles from './WorkPeriod.module.scss';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import cn from 'classnames';
import { ArrowLinkIcon } from '../Icons';
import { TABLET_RESOLUTION } from '@/constants';
import { ModeType } from '@/types';
import { parseText } from '@/utils/parseText';
import { useTranslations } from '@/hooks/useTranslations';

type WorkPeriodItemProps = {
  place: string;
  time: string;
  restriction?: string;
};

type WorkPeriodProps = {
  items: ModeType[];
};

export function WorkPeriodItem({
  place,
  time,
  restriction,
}: WorkPeriodItemProps) {
  return (
    <div className={cn(styles.wrapper, 'custom-work-period')}>
      <p className={cn(styles.place, 'custom-work-period-place')}>
        {parseText(place)}
      </p>
      <p className={cn(styles.time, 'custom-work-period-time')}>
        {parseText(time)}
      </p>
      <span
        className={cn(styles.restriction, 'custom-work-period-restriction')}
      >
        {parseText(restriction)}
      </span>
    </div>
  );
}

export function WorkPeriod({ items }: WorkPeriodProps) {
  const width = useWindowWidth();
  const [open, setOpen] = useState(false);
  const translations = useTranslations();

  const toggle = () => {
    setOpen((prev) => !prev);
  };

  const workPeriodListMobile = cn(styles.workPeriodList, {
    [styles.workPeriodListOpened]: open,
  });

  if (width && width < TABLET_RESOLUTION) {
    return (
      <div className={styles.accordion}>
        <button
          onClick={toggle}
          className={cn(styles.workPeriodTitle, 'custom-footer-link')}
        >
          {translations.workPeriod}
          <div className={styles.workPeriodTitleIcon}>
            <ArrowLinkIcon isOpen={open} />
          </div>
        </button>
        <div className={workPeriodListMobile}>
          {items.map((item) => (
            <WorkPeriodItem
              place={item.name}
              key={item.id}
              restriction={item.restriction}
              time={item.mode}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      <p className={cn(styles.workPeriodTitle, 'custom-work-period-title')}>
        {translations.workPeriod}
      </p>
      <div className={styles.workPeriodList}>
        {items.map((item) => (
          <WorkPeriodItem
            place={item.name}
            key={item.id}
            restriction={item.restriction}
            time={item.mode}
          />
        ))}
      </div>
    </div>
  );
}
