import { Socials } from '@/components/Socials';
import styles from './Footer.module.scss';
import { FooterLink } from '@/components/FooterLinks';

import { WorkPeriod } from '@/components/WorkPeriod';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { TABLET_RESOLUTION } from '@/constants';
import { FooterLinkType, ModeType, SocialType } from '@/types';
import Link from 'next/link';
import { formatPhone } from '@/utils/formatPhone';
import { useTranslations } from '@/hooks/useTranslations';
import cn from 'classnames';

type FooterProps = {
  menu: FooterLinkType[];
  timeTable: ModeType[];
  socials: SocialType[];
  disclaimer: string;
  copyright: string;
  address: string;
  phone: string;
};

export function Footer({
  menu,
  timeTable,
  disclaimer,
  copyright,
  address,
  socials,
  phone,
}: FooterProps) {
  const width = useWindowWidth();
  const translations = useTranslations();
  const howToReachLink = menu[0];

  return (
    <footer className={cn(styles.footer, 'custom-footer')}>
      <div className={styles.wrapper}>
        <img
          loading="lazy"
          src={'/svg/logo.svg'}
          width={64}
          height={83}
          alt="logo"
          className={styles.logo}
        />
        <div className={styles.main}>
          <div className={styles.contacts}>
            <div className={styles.contactsInfo}>
              <div className={styles.address}>
                <p className={styles.addressTitle}>{translations.ourAddress}</p>
                <address className={styles.addressText}>{address}</address>
              </div>
              {phone && (
                <div className={styles.phone}>
                  <p className={styles.addressTitle}>{translations.phone}</p>
                  <Link href={`tel:${phone}`} className={styles.addressText}>
                    {formatPhone(phone)}
                  </Link>
                </div>
              )}
            </div>
            <Socials socials={socials} />
          </div>
          {width && width < TABLET_RESOLUTION ? (
            <>
              <div className={styles.footerLinksItem}>
                <FooterLink
                  text={howToReachLink.name}
                  href={howToReachLink.link}
                />
              </div>
              <div className={styles.workPeriod}>
                <WorkPeriod items={timeTable} />
              </div>
              <div className={styles.links}>
                {menu.slice(1).map((item) => (
                  <div className={styles.footerLinksItem} key={item.name}>
                    <FooterLink text={item.name} href={item.link} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className={styles.workPeriod}>
                <WorkPeriod items={timeTable} />
              </div>
              <div className={styles.links}>
                {menu.map((item) => (
                  <div className={styles.footerLinksItem} key={item.name}>
                    <FooterLink text={item.name} href={item.link} />
                  </div>
                ))}
              </div>
            </>
          )}
          <div className={styles.disclaimer}>
            <p className={styles.disclaimerTitle}>{copyright}</p>
            <p className={styles.disclaimerText}>{disclaimer}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
