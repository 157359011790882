import cn from 'classnames';
import styles from './Container.module.scss';
import { ReactNode } from 'react';

type ContainerProps = {
  title: string;
  children: ReactNode;
  className?: string;
};

export function Container({ title, children, className }: ContainerProps) {
  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <div className={wrapperClassName}>
      <h4 className={cn(styles.title, 'custom-block-title')}>{title}</h4>
      {children}
    </div>
  );
}
