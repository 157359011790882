import styles from './Loader.module.scss';
import cn from 'classnames';

type LoaderProps = {
  fullScreen?: boolean;
};

export function Loader({ fullScreen = false }: LoaderProps) {
  const wrapperClassName = cn(styles.wrapper, {
    [styles.fullScreen]: fullScreen,
  });
  return (
    <div className={wrapperClassName}>
      <img
        loading="lazy"
        className={styles.loaderDI}
        alt="loader"
        src="/svg/loader.svg"
      />
    </div>
  );

  // SPINNER VARIANT 2
  // return (
  //   <div className={styles.wrapper}>
  //     <div className={styles.loader}>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //     </div>
  //   </div>
  // );
}
