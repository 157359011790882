import { BACKEND_URL, CSRF_TOKEN_KEY } from '@/constants';
import axios, { AxiosError } from 'axios';

export const csrfResresh = async () => {
  try {
    const response = await axios.post(`${BACKEND_URL}/api/security/token`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { csrf } = response.data;

    localStorage.setItem(CSRF_TOKEN_KEY, JSON.stringify(csrf));
    return csrf;
  } catch (error) {
    if (error instanceof AxiosError) {
      // eslint-disable-next-line no-console
      console.error(
        'An error occurred during csrf fetching:',
        error.response?.data.message,
      );
      return error.response?.data.message;
    }
  }
};
