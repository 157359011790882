import cn from 'classnames';
import styles from './AdsCard.module.scss';
import { BACKEND_URL } from '@/constants';

import { Backdrop } from '@/components/Backdrop';
import { parseText } from '@/utils/parseText';

type AdsCardProps = {
  title: string;
  description: string;
  img: string;
  href: string;
  className?: string;
};

export function AdsCard({
  title,
  description,
  img,
  href,
  className,
}: AdsCardProps) {
  const cardClassName = cn(styles.card, className);
  const imageUrl = img ? `${BACKEND_URL}${img}` : '/png/empty.png';

  const handleCardClick = () => {
    if (href) window.open(href, '_blank');
  };

  return (
    <div className={cardClassName} onClick={handleCardClick}>
      {img && (
        <img
          loading="lazy"
          src={imageUrl}
          alt="object"
          className={styles.img}
        />
      )}
      <div className={styles.wrapper}>
        <img
          loading="lazy"
          width={24}
          height={24}
          src="/svg/external.svg"
          alt="link"
        />
        <div className={styles.info}>
          <div className={styles.title}>{parseText(title)}</div>
          {description && (
            <div className={styles.description}>{parseText(description)}</div>
          )}
        </div>
      </div>
      {(title || description) && <Backdrop height="40%" />}
    </div>
  );
}
