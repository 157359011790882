import classNames from 'classnames';
import styles from './Divider.module.scss';

type DividerProps = {
  className?: string;
};

export function Divider({ className }: DividerProps) {
  const dividerClassName = classNames(styles.wrapper, className);

  return <div className={dividerClassName} />;
}
