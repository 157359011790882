import styles from './Index.module.scss';
import { AdType, MainPageDataType, SettingsType } from '@/types';
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { fetchData } from '@/services/fetchData';
import { AdsBlock } from '@/components/AdsBlock';
import { MobileEffect, SliderBlock } from '@/components/SliderBlock';
import { ButtonType, CardSize, CardType, TagPosition } from '@/components/Card';
import { Layout } from '@/layout';
import { PROMENAD_CODE } from '@/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectSuccessReg, setSuccessReg } from '@/store';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { VacancyBlock } from '@/components/VacancyBlock';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';
import { parseText } from '@/utils/parseText';
import { BannerSlider } from '@/components/BannerSlider';

const Home = ({
  menu,
  content,
  bannersMobile,
  translations,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const successRegistration = useSelector(selectSuccessReg);
  const [adsArr, setAdsArr] = useState<AdType[]>([]);
  const dispatch = useDispatch();
  const { isTablet } = useAdaptiveWidth();

  useEffect(() => {
    const newAdsArr = content.ads
      .sort(() => Math.random() - Math.random())
      .slice(0, 2);

    setAdsArr(newAdsArr);
  }, [content.ads]);

  useEffect(() => {
    if (successRegistration) {
      toast.dismiss();
      toast.success(translations.successReg);
      dispatch(setSuccessReg(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successRegistration]);

  const backendTitle = { __html: content.meta.h1 || 'Остров мечты' };

  return (
    <Layout data={menu} meta={content.meta}>
      <main id="dapanov">
        <h1 className={styles.heading} dangerouslySetInnerHTML={backendTitle} />
        <BannerSlider banners={content.banners} bannersMobile={bannersMobile} />
        <SliderBlock
          items={content.objects}
          title={translations.objectsTitle}
          cardType={CardType.Filled}
          button={translations.objectsButton}
          link="/objects"
          size={CardSize.XL}
          tagPosition={TagPosition.Bottom}
          mobileEffect={MobileEffect.Cards}
        />
        <SliderBlock
          items={content.entertainments}
          cardType={CardType.Filled}
          button={translations.tipsButton}
          link="/entertainments"
          size={CardSize.M}
          title={translations.tipsTitle}
          buttonType={ButtonType.Arrow}
          tagPosition={TagPosition.Top}
          mobileEffect={MobileEffect.Slider}
        />
        <SliderBlock
          items={content.events}
          cardType={CardType.Panel}
          button={translations.eventsTitle}
          link="/events"
          size={CardSize.S}
          tagPosition={TagPosition.Top}
          title={translations.eventsButton}
          mobileEffect={MobileEffect.Slider}
        />
        <AdsBlock ads={isTablet ? adsArr.slice(0, 1) : adsArr} />
        <SliderBlock
          items={content.trades}
          cardType={CardType.Panel}
          button={parseText(translations.tradesButton)}
          link={`/objects/${PROMENAD_CODE}`}
          size={CardSize.XS}
          title={translations.tradesTitle}
          mobileEffect={MobileEffect.Slider}
        />
        <SliderBlock
          items={content.news}
          cardType={CardType.Panel}
          button={translations.newsButton}
          link="/news"
          size={CardSize.S}
          title={translations.newsTitle}
          mobileEffect={MobileEffect.Slider}
          withYear
        />
        {content.vacancyBanner && (
          <VacancyBlock
            text={content.vacancyBanner.name}
            image={content.vacancyBanner.picture}
            imageMob={content.vacancyBanner.pictureMob}
            tag={content.vacancyBanner.tag}
            button={content.vacancyBanner.button}
            link={content.vacancyBanner.link}
          />
        )}
        <div className={styles.mobileAds}>
          <AdsBlock ads={adsArr.slice(1, 2)} />
        </div>
      </main>
    </Layout>
  );
};

export async function getServerSideProps({
  locale,
}: GetServerSidePropsContext) {
  const content: MainPageDataType = await fetchData({
    url: `/main/view`,
    locale,
  });

  const menu: SettingsType = await fetchData({
    url: `/settings/view`,
    locale,
  });

  if (!content) return { notFound: true };

  const filteredBanners = content.banners.filter(
    ({ videoMob, video, picture }) => {
      if (videoMob) return videoMob;

      if (video) return video && videoMob;

      return picture;
    },
  );

  const bannersMobile = [...filteredBanners, ...filteredBanners];

  return {
    props: {
      content,
      menu,
      bannersMobile,
      translations: menu.translations['/'],
    },
  };
}

export default Home;
